import { FetchBaseQueryMeta, fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { matchesUrl } from 'findem-helpers';
import { IHtmlLibraryCategory, IHtmlLibraryComponent, IHtmlCategoryRequestParams, IHtmlComponentRequestParams } from 'findem-types';

// Types
import { IHtmlCategoryCreateRequestParams, IHtmlCategoryUpdateRequestParams, IHtmlCategoryDeleteRequestParams, IHtmlComponentCreateRequestParams, IHtmlComponentUpdateRequestParams, IHtmlComponentDeleteRequestParams, IHtmlComponentUpdateCategoryRequestParams, IHtmlComponentUpdateScreenshotRequestParams } from '../types/html-library';

export const htmlLibraryApi = createApi({
  reducerPath: 'htmlLibrary',
  tagTypes: ['htmlCategories', 'htmlComponents'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${matchesUrl}/hm/api`
  }),
  endpoints: (build) => ({
    // Categories
    getHtmlCategory: build.query<IHtmlLibraryCategory[], IHtmlCategoryRequestParams>({
      providesTags: ['htmlCategories'],
      query: (params) => ({
        url: `/cms/html-library-category`,
        params: {
          account_id: params.accountId,
          joid: params.joid,
        },
        credentials: 'include',
        method: 'GET',
      }),
    }),

    createHtmlCategory: build.mutation<IHtmlLibraryCategory, IHtmlCategoryCreateRequestParams>({
      invalidatesTags: ['htmlCategories'],
      query: (params) => ({
        url: `/cms/html-library-category`,
        params: {
          account_id: params.accountId,
          joid: params.joid,
        },
        credentials: 'include',
        method: 'POST',
        body: {
          title: params.title,
          description: params.description,
          is_org_category: params.isOrgCategory ?? false,
        }
      }),
    }),

    updateHtmlCategory: build.mutation<IHtmlLibraryCategory, IHtmlCategoryUpdateRequestParams>({
      invalidatesTags: ['htmlCategories'],
      query: (params) => ({
        url: `/cms/html-library-category`,
        params: {
          account_id: params.accountId,
          joid: params.joid,
        },
        credentials: 'include',
        method: 'PUT',
        body: {
          ref: params.ref,
          title: params.title,
          description: params.description,
        }
      }),
    }),

    deleteHtmlCategory: build.mutation<{ success: boolean }, IHtmlCategoryDeleteRequestParams>({
      invalidatesTags: ['htmlCategories'],
      query: (params) => ({
        url: `/cms/html-library-category`,
        params: {
          account_id: params.accountId,
          joid: params.joid,
          ref: params.ref,
        },
        credentials: 'include',
        method: 'DELETE',
      }),
    }),

    // Components

    getHtmlComponents: build.query<IHtmlLibraryComponent[], IHtmlComponentRequestParams>({
      providesTags: ['htmlComponents'],
      query: (params) => ({
        url: `/cms/html-library`,
        params: {
          account_id: params.accountId,
          joid: params.joid,
        },
        credentials: 'include',
        method: 'GET',
      }),
    }),

    createHtmlComponent: build.mutation<IHtmlLibraryComponent, IHtmlComponentCreateRequestParams>({
      invalidatesTags: ['htmlComponents'],
      query: (params) => ({
        url: `/cms/html-library`,
        params: {
          account_id: params.accountId,
          joid: params.joid,
        },
        credentials: 'include',
        method: 'POST',
        body: {
          title: params.title,
          description: params.description,
          is_global_template: params.isGlobalTemplate ?? false,
          is_org_template: params.isOrgTemplate ?? false,
          category: params.category,
          content: params.content,
          image: params.image,
        }
      }),
    }),

    updateHtmlComponent: build.mutation<IHtmlLibraryCategory, IHtmlComponentUpdateRequestParams>({
      invalidatesTags: ['htmlComponents'],
      query: (params) => ({
        url: `/cms/html-library`,
        params: {
          account_id: params.accountId,
          joid: params.joid,
        },
        credentials: 'include',
        method: 'PUT',
        body: {
          ref: params.ref,
          title: params.title,
          description: params.description,
          category: params.category,
          content: params.content,
          image: params.image,
        }
      }),
    }),

    deleteHtmlComponent: build.mutation<{ success: boolean }, IHtmlComponentDeleteRequestParams>({
      invalidatesTags: ['htmlComponents'],
      query: (params) => ({
        url: `/cms/html-library`,
        params: {
          account_id: params.accountId,
          joid: params.joid,
          ref: params.ref,
        },
        credentials: 'include',
        method: 'DELETE',
      }),
    }),

    updateHtmlComponentCategories: build.mutation<{ success: boolean }, IHtmlComponentUpdateCategoryRequestParams>({
      invalidatesTags: ['htmlComponents'],
      query: (params) => ({
        url: `/cms/html-library/category`,
        params: {
          account_id: params.accountId,
          joid: params.joid,
        },
        credentials: 'include',
        method: 'PUT',
        body: {
          new_ref: params.newRef,
          component_ids: params.componentIds
        }
      }),
    }),

    updateHtmlComponentScreenshot: build.mutation<{ success: boolean }, IHtmlComponentUpdateScreenshotRequestParams>({
      invalidatesTags: ['htmlComponents'],
      query: (params) => {
        const formData = new FormData();
        formData.append('ref', params.ref);
        formData.append('screenshot', params.screenshot as Blob, params.screenshot.name);

        return {
          url: `/cms/html-library/screenshot`,
          params: {
            account_id: params.accountId,
            joid: params.joid,
          },
          credentials: 'include',
          method: 'PUT',
          body: formData
        }
      },
    }),

  })
});

export const {
  useGetHtmlCategoryQuery,
  useCreateHtmlCategoryMutation,
  useUpdateHtmlCategoryMutation,
  useDeleteHtmlCategoryMutation,

  useGetHtmlComponentsQuery,
  useCreateHtmlComponentMutation,
  useUpdateHtmlComponentMutation,
  useDeleteHtmlComponentMutation,
  useUpdateHtmlComponentCategoriesMutation,
  useUpdateHtmlComponentScreenshotMutation,
} = htmlLibraryApi;
