import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { billingUrl } from 'findem-helpers';
import { ISmbPlan, IUsageLimits, PlanType, ISubscription } from 'findem-types';

export interface IGetAccountPlanParams {
  accountId: string;
}

export const plansApi = createApi({
  reducerPath: 'plans',
  baseQuery: fetchBaseQuery({
    baseUrl: billingUrl
  }),
  tagTypes: ['accountPlan'],
  endpoints: (build) => ({
    getPlans: build.query<ISmbPlan[], void | null>({
      query: () => ({
        url: 'billing/api/plans',
        credentials: 'include',
        method: 'GET',
      })
    }),

    getAccountPlan: build.query<ISubscription[], IGetAccountPlanParams>({
      providesTags: ['accountPlan'],
      query: (params: IGetAccountPlanParams) => ({
        url: `billing/api/subscriptions?account_id=${params.accountId}`,
        credentials: 'include',
        method: 'GET',
      })
    }),
  })
});

export const {
  useGetPlansQuery,
  useGetAccountPlanQuery
} = plansApi;
