import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';

import { IUserPreference } from 'findem-types';
import { matchesUrl } from 'findem-helpers';

export const userPreferencesApi = createApi({
  reducerPath: 'user_preferences',
  baseQuery: fetchBaseQuery({
    baseUrl: matchesUrl
  }),
  tagTypes: ['userPref'],
  endpoints: (build) => ({
    getUserPreference: build.query<IUserPreference, string>({
      providesTags: ['userPref'],
      query: (user_id) => ({
        url: `hm/api/user/pref?uid=${user_id}&ai_preferences=true`,
        credentials: 'include'
      }),
      transformResponse: (response: { user_pref: IUserPreference }, meta, arg) => {
        return response.user_pref;
      },
    }),

    postUserPreference: build.mutation({
      query: (body) => ({
        url: `hm/api/user/pref?uid=${body.uid}`,
        credentials: 'include',
        headers: {
          'Content-type': 'application/json'
        },
        method: 'POST',
        body: {
          type: 'CreateUpdate',
          ...body
        }
      }),
    }),

    postUserPreferences: build.mutation({
      query: (body) => ({
        url: `hm/api/preferences`,
        credentials: 'include',
        headers: {
          'Content-type': 'application/json'
        },
        method: 'POST',
        body
      }),
    })
  })
});

export const {
  useGetUserPreferenceQuery,
  usePostUserPreferenceMutation,
  usePostUserPreferencesMutation
} = userPreferencesApi;
