import React, { Suspense} from 'react';
import { Routes, Route } from 'react-router-dom';
import { Loader } from 'maui';

// Styled + Components
const AccountSettings = React.lazy(() => import('../AccountSettings'));
const CheckoutReturnPage = React.lazy(() => import('../Checkout/ReturnPage'));
const Error404 = React.lazy(() => import('../ErrorBoundary/Error404'));
const HtmlLibrary = React.lazy(() => import('../HtmlLibrary'));
const Workflows = React.lazy(() => import('../Workflows'));
const Workflow = React.lazy(() => import('../Workflow'));

const AppRoutes = () => {
  return (
    <Suspense fallback={<Loader $fullPage />}>
      <Routes>
        <Route path='*' element={<Error404 />} />
        <Route path='/' element={<AccountSettings />} />
        <Route path="/checkout/return" element={<CheckoutReturnPage />} />
        <Route path="/workflows" element={<Workflows />} />
        <Route path="/workflow/:workflowId" element={<Workflow />} />
        <Route path="/html" element={<HtmlLibrary />} />
      </Routes>
    </Suspense>
  )
}

export default AppRoutes;