import { Icon, notification } from 'maui';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Sidebar as UISidebar } from 'findem-ui-catalog';
import { IUser } from 'findem-types';
import { appNextUrl, isFindemIoDomain, isSmbApp } from 'findem-helpers';

import { FullStory } from '@fullstory/browser';

// APIs
import { useLogoutMutation } from '../../services/users';

// Types
import { RootState } from '../../store';

// Utils
import { appStateActions } from '../../reducers/appState';

// Styled + Components
import CreditsTracker from './ExtraContendAfterMenu/CreditsTracker';

const sidebarMenuItems = [
  {
    key: 'workflows',
    label: <Link to='/workflows'>Workflow Automation</Link>,
    icon: <Icon icon='smart_toy' />
  }
]
const Sidebar = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  //--------------------------------------------------------------------------------
  // Store
  //--------------------------------------------------------------------------------

  const currentUser: IUser = useSelector(
    (state: RootState) => state.appState.currentUser
  );

  const sidebarCollapsed: boolean = useSelector(
    (state: RootState) => state.appState.sidebarCollapsed
  );

  //--------------------------------------------------------------------------------
  // APIs
  //--------------------------------------------------------------------------------

  const [logout, { isLoading: isLogoutLoading }] = useLogoutMutation();

  //--------------------------------------------------------------------------------
  // Callbacks
  //--------------------------------------------------------------------------------

  const handleCollapse = useCallback((state: boolean) => {
    dispatch(appStateActions.setSidebarCollapsed(state));
  }, []);

  const handleCustomPathNameLogic = useCallback((pathName: string) => {
    if (pathName === '') {
      return 'dashboard';
    } else if (pathName.startsWith('workflow')) {
      return 'workflows';
    }

    return pathName;
  }, [location]);

  const handleLogout = useCallback(async () => {
    try {
      const res = await logout();

      if ('error' in res) {
        throw 'data' in res.error ? res.error.data : res.error;
      }

      window.location.href = appNextUrl;
    } catch (err) {
      console.error(err);

      notification.error({
        message: 'Error Logging Out',
        description: `${err}`
      });
    }
  }, []);

  return (
    <UISidebar
      currentUser={currentUser}
      collapsed={sidebarCollapsed}
      onCollapseChange={handleCollapse}
      consumerApp='account'
      extraContentAfter={
        isSmbApp() && <CreditsTracker />
      }
      logoutProps={isFindemIoDomain(window.location.origin) ? {
        onLogout: handleLogout,
        isLoading: isLogoutLoading
      } : undefined}
      fullStorySessionUrl={FullStory('getSession', { format: 'url' })}
    />
  )
}

export default Sidebar;