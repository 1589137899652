import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { IAccountUsage, IOrg, IPaymentCustomerResponse, IPaymentMethods } from 'findem-types';
import { billingUrl, sourcingUrl } from 'findem-helpers';
import { ICancelSubscriptionParams, ICheckoutStatus, IGetAccountPlanParams, IGetPaymentMethodParams, IGetStripePubKeyParams, IPreviewSubscriptionUpgrade, IPurchaseOneTimePlanParams, IStripePubKey, ITax, IUpdateSubscriptionParams } from '../types/billing';
import { IStripeAddress } from 'findem-ui-catalog';


export const billingApi = createApi({
  reducerPath: 'billingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: billingUrl
  }),
  tagTypes: ['planUsage'],
  endpoints: (build) => ({
    getStripePubKey: build.query<IStripePubKey, IGetStripePubKeyParams>({
      keepUnusedDataFor: 300,
      query: (params) => ({
        url: '/billing/api/stripe_accounts',
        params: {
          ...(params.targetUserId ? {
            target_user_id: params.targetUserId
          } : {})
        },
        credentials: 'include',
        method: 'GET',
      })
    }),

    getCheckoutSessions: build.query<unknown, void | null>({
      query: () => ({
        url: '/billing/api/create-checkout-session',
        credentials: 'include',
        method: 'POST',
        body: {
          plan_id: 'prod_QdF8X7BAqHUFgK',
          target_user_id: '66bffc6da4acc96a13f9bbef',
        }
      })
    }),

    getCheckoutStatus: build.query<ICheckoutStatus, string>({
      query: (sessionId) => ({
        url: '/billing/api/session-status',
        params: {
          session_id: sessionId
        },
        credentials: 'include',
        method: 'GET',
      })
    }),

    getAccountUsage: build.query<IAccountUsage, IGetAccountPlanParams>({
      providesTags: ['planUsage'],
      query: (params: IGetAccountPlanParams) => ({
        url: `billing/api/usage?account_id=${params.accountId}`,
        credentials: 'include',
        method: 'GET',
      })
    }),

    // Payments

    getPaymentCustomer: build.query<IPaymentCustomerResponse, void | null>({
      query: (params) => ({
        url: '/billing/api/payment_customer',
        credentials: 'include',
        method: 'GET',
      }),
    }),

    updateSubscription: build.mutation<unknown, IUpdateSubscriptionParams>({
      // Dont't invalidate here, let debounce handle
      // invalidatesTags: ['planUsage'],
      query: (params) => ({
        url: `billing/api/update_subscription`,
        credentials: 'include',
        method: 'POST',
        body: params
      })
    }),

    cancelSubscription: build.mutation<unknown, ICancelSubscriptionParams>({
      // Dont't invalidate here, let debounce handle
      // invalidatesTags: ['planUsage'],
      query: (params) => ({
        url: `billing/api/cancel_subscription`,
        credentials: 'include',
        method: 'POST',
        body: params
      })
    }),

    purchaseOneTimePlan: build.mutation<unknown, IPurchaseOneTimePlanParams>({
      // Dont't invalidate here, let debounce handle
      // invalidatesTags: ['planUsage'],
      query: (params) => ({
        url: `billing/api/purchase_onetime_plan`,
        credentials: 'include',
        method: 'POST',
        body: params
      })
    }),

    undoCancelSubscription: build.mutation<unknown, string>({
      query: (planId: string) => ({
        url: `billing/api/undo_cancel_subscription`,
        credentials: 'include',
        method: 'POST',
        body: {
          plan_id: planId
        }
      })
    }),

    undoDowngradeSubscription: build.mutation<unknown, string>({
      query: (planId: string) => ({
        url: `billing/api/undo_downgrade_subscription`,
        credentials: 'include',
        method: 'POST',
        body: {
          plan_id: planId
        }
      })
    }),

    calculateTax: build.query<ITax, string>({
      // invalidatesTags: ['planUsage'],
      query: (planId) => ({
        url: `billing/api/calculate_tax`,
        credentials: 'include',
        method: 'POST',
        body: {
          plan_id: planId
        }
      })
    }),

    previewSubscriptionUpgrade: build.query<IPreviewSubscriptionUpgrade, string>({
      query: (planId) => ({
        url: `billing/api/preview_subscription_upgrade`,
        credentials: 'include',
        method: 'POST',
        body: {
          plan_id: planId
        }
      })
    }),

    // Overage

    enableOverage: build.mutation<unknown, void>({
      invalidatesTags: ['planUsage'],
      query: () => ({
        url: `billing/api/enable_overage`,
        credentials: 'include',
        method: 'POST',
      })
    }),

    disableOverage: build.mutation<IPaymentMethods, void>({
      invalidatesTags: ['planUsage'],
      query: () => ({
        url: `billing/api/disable_overage`,
        credentials: 'include',
        method: 'POST',
      })
    }),

    setCustomerOnboardingState: build.mutation<unknown, {
      complete: boolean
    }>({
      query: ({
        complete
      }) => ({
        url: '/billing/api/customer_onboarding_progress',
        credentials: 'include',
        method: 'POST',
        body: {
          complete: complete
        }
      })
    })
  })
});

export const {
  useGetStripePubKeyQuery,
  useGetCheckoutSessionsQuery,
  useGetCheckoutStatusQuery,
  useGetAccountUsageQuery,

  // Payments
  useGetPaymentCustomerQuery,
  useUpdateSubscriptionMutation,
  usePurchaseOneTimePlanMutation,
  useCancelSubscriptionMutation,
  useUndoCancelSubscriptionMutation,
  useUndoDowngradeSubscriptionMutation,
  useCalculateTaxQuery,
  usePreviewSubscriptionUpgradeQuery,

  // Customer Onboarding
  useSetCustomerOnboardingStateMutation,

  // Overage
  useEnableOverageMutation,
  useDisableOverageMutation,
 } = billingApi;
