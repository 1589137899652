import { Button, Result } from 'maui';
import React, { Component } from 'react';

import { appNextUrl, constructUrlWithParams, isFindemIoDomain, isSmbApp, matchesUrl } from 'findem-helpers';

const LoggedOutSplash = () => (
  <Result
    title="Please Login"
    subTitle="Sorry, you must be signed in to access this page."
    extra={
      <Button
        type='primary'
        size='large'
        href={isSmbApp()
          ? constructUrlWithParams(`${appNextUrl}/auth/login`, {
              redirect_url: encodeURIComponent(window.location.href)
            })
          : `${matchesUrl}/auth/login`}
        target={isSmbApp()
          ? '_self'
          : '_blank'
        }
        rel="noopener noreferrer"
      >
        Login Here
      </Button>
    }
  />
);

export default LoggedOutSplash;
