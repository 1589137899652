import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { IGetQuestionnarireParams, IPlatformRole, IPlatformUser, IQuestionnaire, IQuestionnaireQA, IUser, IUserList } from 'findem-types';
import { analyticsUrl, constructUrlWithParams, paymentsUrl, transformUserRes } from 'findem-helpers';

import {
  matchesUrl,
  pubUrl,
  searchUrl,
  settingsUrl
} from 'findem-helpers';
import { IDeletePlatformUserParams, IGetPlatformUserParams, IInviteUserParams, IUpdatePasswordParams, IUpdatePlatformUsersParams, IUpdateUserParams } from '../types/users';

export const usersApi = createApi({
  reducerPath: 'users',
  tagTypes: ['currentUser', 'users', 'UserRoles', 'PlatformUsers', 'Questionnaires'],
  baseQuery: fetchBaseQuery({
    baseUrl: matchesUrl
  }),
  endpoints: (build) => ({
    getCurrentUser: build.query<IUser, void>({
      providesTags: ['currentUser'],
      query: () => ({
        url: `/api/user_info`,
        credentials: 'include'
      }),
      transformResponse: transformUserRes,
    }),

    getAllUsers: build.query<IUserList[], void>({
      query: () => ({ url: `/hm/api/users`, credentials: 'include' }),
    }),

    getUser: build.query<IUser, string>({
      query: (id) => ({
        url: `${pubUrl}/pub/api/user_info`,
        credentials: 'include',
        method: 'POST',
        body: {
          id
        }
      }),
    }),

    getAllAccountUsers: build.query<IUserList[], { joId: string;  }>({
      query: (params) => ({
        url: constructUrlWithParams(`${paymentsUrl}/api/fetch_users`, {
          joid: params.joId,
        }),
        credentials: 'include'
      }),
      transformResponse: (response: any, meta, arg) => response.users
    }),

    getUserRoles: build.query<string[], string>({
      providesTags: (_r, _e, uid) => ([
        { type: 'UserRoles', id: uid }
      ]),
      query: (uid) => ({
        url: `${matchesUrl}/hm/api/user-roles?uid=${uid}`,
        credentials: 'include',
        method: 'POST'
      })
    }),

    updateUser: build.mutation<IUser, IUpdateUserParams>({
      invalidatesTags: ['currentUser'],
      query: (params) => ({
        url: `api/user_info`,
        credentials: 'include',
        method: 'POST',
        body: {
          name: params.name,
          company_name: params.companyName,
          company_website: params.companyWebsite,
          role: params.role,
          phone_number: params.phone,
          calendar_link: params.calendarLink,
        }
      })
    }),

    updatePassword: build.mutation<IUser, IUpdatePasswordParams>({
      query: (params) => ({
        url: `api/passwordReset`,
        credentials: 'include',
        method: 'POST',
        body: {
          old_password: params.oldPassword,
          new_password: params.newPassword,
        }
      })
    }),

    logout: build.mutation<unknown, void>({
      query: () => ({
        url: `${matchesUrl}/auth/logout?api=true`,
        credentials: 'include',
        method: 'GET'
      }),
    }),

    // Platform

    getPlatformUsers: build.query<IPlatformUser[], IGetPlatformUserParams>({
      providesTags: (_r, _e, params: IGetPlatformUserParams) => {
        return [{
          type: 'PlatformUsers',
          id: params.resource_id ?? 'all',
        }]
      },
      query: (params) => ({
        url: params.resource_id
          ? `${analyticsUrl}/analytics/api/platform_users/${params.resource_id}`
          : `${analyticsUrl}/analytics/api/platform_users`,
        params: {
          account_id: params.accountId,
        },
        credentials: 'include',
      }),
      transformResponse: (response: any, meta, arg) => response.platform_users
    }),

    getPlatformRoles: build.query<IPlatformRole[], void>({
      keepUnusedDataFor: 300,
      query: () => ({
        url: `${analyticsUrl}/analytics/api/platform_roles`,
        params: {
          scope: 'all'
        },
        credentials: 'include'
      }),
      transformResponse: (response: any, meta, arg) => response.platform_roles
    }),

    updatePlatformUser: build.mutation<IPlatformUser, IUpdatePlatformUsersParams>({
      invalidatesTags: [{
        type: 'PlatformUsers',
        id: 'all'
      }],
      query: (params) => {
        const { accountId, ...restOfParams} = params;

        return {
          url: `${analyticsUrl}/analytics/api/update_user_roles`,
          params: {
            account_id: accountId
          },
          method: 'POST',
          credentials: 'include',
          body: restOfParams
        }
      },
      transformResponse: (response: any, meta, arg) => response.joined_user
    }),

    deletePlatformUser: build.mutation<IPlatformUser, IDeletePlatformUserParams>({
      invalidatesTags: [{
        type: 'PlatformUsers',
        id: 'all'
      }],
      query: (params) => {
        const { accountId, ...restOfParams} = params;

        return {
          url: `${analyticsUrl}/analytics/api/platform_users`,
          params: {
            account_id: accountId
          },
          method: 'DELETE',
          credentials: 'include',
          body: restOfParams
        }
      },
      transformResponse: (response: any, meta, arg) => response.joined_user
    }),


    // Questionaires

    getQuestionnaires: build.query<IQuestionnaireQA[], IGetQuestionnarireParams>({
      providesTags: (_r, _e, params) => [{
        type: 'Questionnaires',
        id: params.question_scope
      }],
      query: (params) => ({
        url: `${pubUrl}/pub/api/onboarding`,
        params: {
          question_scope: params.question_scope
        },
        credentials: 'include',
        method: 'GET',
      }),
    }),

    createQuestionnare: build.mutation<IQuestionnaire, IQuestionnaire>({
      invalidatesTags: (_r, _e, params) => [{
        type: 'Questionnaires',
        id: params.question_scope
      }],
      query: (params) => ({
        url: `${pubUrl}/pub/api/onboarding`,
        credentials: 'include',
        method: 'POST',
        body: params,
      }),
    }),

    updateQuestionnare: build.mutation<IQuestionnaire, IQuestionnaire>({
      invalidatesTags: (_r, _e, params) => [{
        type: 'Questionnaires',
        id: params.question_scope
      }],
      query: (params) => ({
        url: `${pubUrl}/pub/api/onboarding`,
        credentials: 'include',
        method: 'PUT',
        body: params,
      }),
    }),

    deleteQuestionnaire: build.mutation<unknown, IGetQuestionnarireParams>({
      invalidatesTags: (_r, _e, params) => [{
        type: 'Questionnaires',
        id: params.question_scope
      }],
      query: (params) => ({
        url: `${pubUrl}/pub/api/onboarding`,
        params: {
          question_scope: params.question_scope
        },
        credentials: 'include',
        method: 'DELETE',
      }),
    }),

    // Invite User

    inviteUser: build.mutation<unknown, IInviteUserParams>({
      query: (params) => ({
        url: `${matchesUrl}/auth/invite`,
        credentials: 'include',
        method: 'POST',
        body: {
          ...params,
        }
      }),
    }),

  })
});

export const {
  useGetCurrentUserQuery,
  useGetAllUsersQuery,
  useGetAllAccountUsersQuery,
  useGetUserQuery,
  useGetUserRolesQuery,
  useUpdateUserMutation,
  useUpdatePasswordMutation,
  useLogoutMutation,

  // Platform
  useGetPlatformUsersQuery,
  useGetPlatformRolesQuery,
  useUpdatePlatformUserMutation,
  useDeletePlatformUserMutation,

  // Questionnaires
  useGetQuestionnairesQuery,
  useCreateQuestionnareMutation,
  useUpdateQuestionnareMutation,
  useDeleteQuestionnaireMutation,

  // Invite User
  useInviteUserMutation,
} = usersApi;
