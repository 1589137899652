import React from 'react';
import { useSelector } from 'react-redux';
import { CreditsTracker as CommonCreditsTracker } from 'findem-ui-catalog';

// Types
import { RootState } from '../../../../store';

// Styled + Components
import { IAccountUsage, ISmbPlan, ISubscription, PlatformPCRoleTags } from 'findem-types';

interface ICreditsTrackerProps {
}

const CreditsTracker: React.FC<ICreditsTrackerProps> = () => {

  //--------------------------------------------------------------------------------
  // Store
  //--------------------------------------------------------------------------------

  const accountPlan: ISmbPlan | undefined = useSelector((state: RootState) =>
    state.smbState.accountPlan
  );

  const accountSubscription: ISubscription[] | undefined = useSelector((state: RootState) =>
    state.smbState.accountSubscription
  );

  const accountUsage: IAccountUsage | undefined = useSelector((state: RootState) =>
    state.smbState.accountUsage
  );

  const isSmbAccountAdmin: boolean = useSelector((state: RootState) =>
    state.smbState.isAccountAdmin
  );

  const isSmbAccountOwner: boolean = useSelector((state: RootState) =>
    state.smbState.isAccountOwner
  );

  const isSidebarCollapsed = useSelector((state: RootState) =>
    state.appState.sidebarCollapsed
  );

  //--------------------------------------------------------------------------------
  // Memos
  //--------------------------------------------------------------------------------

  const isBasicPlan = React.useMemo(() => {
    return !accountPlan || accountPlan?.platform_role_tags.includes(PlatformPCRoleTags.smb_pc_tier_0);
  }, [accountPlan]);

  return (
    <CommonCreditsTracker
      isSidebarCollapsed={isSidebarCollapsed}
      details={{
        accountPlan,
        accountUsage,
        accountSubscription,
        isSmbAccountAdmin,
        isSmbAccountOwner,
        isBasicPlan
      }}
    />
  );
}

export default CreditsTracker;