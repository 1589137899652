import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { searchUrl } from 'findem-helpers';

interface IProfileParams {
  joid: string;
  profile_ids: string[];
  detail?: string;
  sandbox?: boolean;
  bust_cache?: boolean;
}

export const profilesApi = createApi({
  reducerPath: 'profiles',
  baseQuery: fetchBaseQuery({
    baseUrl: searchUrl
  }),
  endpoints: (build) => ({
    // Temporary for Email Function only.
    // Clean this up if you want to use this.
    getProfiles: build.query<any, IProfileParams>({
      query: (body) => {
        return {
          url: `pub/api/profile`,
          credentials: 'include',
          method: "POST",
          body: {
            detail: body.detail ? body.detail : 'true',
            joid: body.joid,
            prids: body.profile_ids,
            bust_cache: body.bust_cache ? body.bust_cache : false,
            type: 'Profiles',
            ...(body?.sandbox && { sandbox: true }),
          },
        };
      },
    }),
  })
});

export const {
  useGetProfilesQuery,
 } = profilesApi;
