import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { IReferrer } from 'findem-types';
import { matchesUrl, settingsUrl } from 'findem-helpers';

export interface ISaveMatchFeedbackParams {
  type: string;
  sub_type: string | undefined;
  prid: string;
  icpid: string;
  joid: string;
  state?: string;
  recruiter_assessment?: string;
  rejection_reason?: string | number;
  clear_campaign_cache?: boolean;
  profile_refresh_status?: string;
}

export interface ICreateProfileResponse {
  created_at: string;
  ext_refs: string[];
  ext_src: string;
  ext_src_ref: string;
  name: string;
  profile: {
    ref: string;
  };
  sourced_by: string;
  ref?: string;
  error?: boolean;
}

interface ICreateProfileRequestParams {
  name: string;
  email: string;
}

interface IConnectInviteRequestParams {
  type: string;
  prid: string;
  inviteeName: string;
  inviteeEmail: string;
  joId?: string;
}

interface IRemoveEmailRequestParams {
  uid: string;
  platform: string;
  invitee_email: string;
}

interface IUpdateEmailRequestParams {
  uid: string;
  profileId: string;
  title?: string;
  inviteeEmail: string;
  inviteeName: string;
  emailLimit?: number;
  emailArchiveCriteria: {
    bounce: boolean,
    profanity: boolean,
    wrongEmails: boolean,
    notInterested: boolean,
  }
}

export const emailsApi = createApi({
  reducerPath: 'emails',
  baseQuery: fetchBaseQuery({
    baseUrl: `${matchesUrl}/hm/api/email_templates`
  }),
  tagTypes: ['GlobalReferrers', 'Profiles', 'CampaignReplies', 'CampaignStats', 'CustomDomains', 'LinkBranding', 'EmailNames'],
  keepUnusedDataFor: 300,
  endpoints: (build) => ({
    getGlobalReferrers: build.query<IReferrer[], string>({
      providesTags: ['GlobalReferrers'],
      query: (userId: string) => ({
        url: '',
        credentials: 'include',
        method: 'POST',
        body: {
          type: 'FetchGlobalReferrers',
          joid: userId
        }
      }),
      transformResponse: (response: any, meta, arg) => response.filter((res: IReferrer) => res.enabled)
    }),

    // Email Connect

    createProfile: build.mutation<
      ICreateProfileResponse,
      ICreateProfileRequestParams
    >({
      query: (requestParams: ICreateProfileRequestParams) => ({
        url: `${matchesUrl}/hm/api/profile`,
        credentials: 'include',
        method: 'POST',
        body: {
          type: 'CreateProfile',
          profile: {
            ...requestParams
          }
        }
      }),
      transformResponse: (response: any, meta, arg) => response
    }),

    connectInvite: build.mutation<string, IConnectInviteRequestParams>({
      query: (requestParams: IConnectInviteRequestParams) => ({
        url: `${settingsUrl}/settings/api/connect-invite`,
        credentials: 'include',
        method: 'POST',
        body: {
          type: requestParams.type,
          invitee_prid: requestParams.prid,
          invitee_linkedin: encodeURIComponent(''),
          invitee_name: encodeURIComponent(requestParams.inviteeName),
          invitee_email: requestParams.inviteeEmail,
          fm_uid: requestParams.joId
        }
      }),
      transformResponse: (response: string, meta, arg) => response
    }),

    updateEmail: build.mutation<string, IUpdateEmailRequestParams>({
      invalidatesTags: ['GlobalReferrers'],
      query: (params) => ({
        url: `${settingsUrl}/settings/api/referrer/${params.uid}`,
        credentials: 'include',
        method: 'PUT',
        body: {
          prid: params.profileId,
          title: params.title,
          invitee_email: params.inviteeEmail,
          invitee_name: params.inviteeName,
          email_limit: params.emailLimit,
          email_archive_criteria: {
            bounce: params.emailArchiveCriteria.bounce,
            profanity: params.emailArchiveCriteria.profanity,
            wrong_emails: params.emailArchiveCriteria.wrongEmails,
            not_interested: params.emailArchiveCriteria.notInterested,
          }
        }
      }),
    }),

    removeEmail: build.mutation<string, IRemoveEmailRequestParams>({
      invalidatesTags: ['GlobalReferrers'],
      query: (params) => ({
        url: `${settingsUrl}/settings/api/referrer_auth/${params.uid}`,
        credentials: 'include',
        method: 'DELETE',
        body: {
          platform: params.platform,
          invitee_email: params.invitee_email,
        }
      }),
    }),

  })
});

export const {
  useGetGlobalReferrersQuery,
  useConnectInviteMutation,
  useCreateProfileMutation,
  useUpdateEmailMutation,
  useRemoveEmailMutation,
} = emailsApi;
