import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IAccountUsage, IPlatformUser, ISmbPlan, ISubscription, IUser } from 'findem-types';

export interface ISmbState {
  isAccountAdmin: boolean;
  isAccountOwner: boolean;
  isLoadingPlatformRoles: boolean;

  accountPlan?: ISmbPlan;
  accountSubscription?: ISubscription[];
  accountUsage?: IAccountUsage;
}

export const initialState: ISmbState = {
  isAccountAdmin: false,
  isAccountOwner: false,
  isLoadingPlatformRoles: false,
};

const smbState = createSlice({
  name: 'smbState',
  initialState,
  reducers: {
    reset: () => initialState,

    // Access
    setIsAccountAdmin(state: ISmbState, action: PayloadAction<boolean>) {
      state.isAccountAdmin = action.payload;
    },

    setIsAccountOwner(state: ISmbState, action: PayloadAction<boolean>) {
      state.isAccountOwner = action.payload;
    },

    setIsLoadingPlatformRoles(state: ISmbState, action: PayloadAction<boolean>) {
      state.isLoadingPlatformRoles = action.payload;
    },

    // Plans
    setAccountPlan: (state: ISmbState, action: PayloadAction<ISmbPlan>) => {
      state.accountPlan = action.payload;
    },
    setAccountSubscription: (state: ISmbState, action: PayloadAction<ISubscription[]>) => {
      state.accountSubscription = action.payload;
    },
    setAccountUsage: (state: ISmbState, action: PayloadAction<IAccountUsage>) => {
      state.accountUsage = action.payload
    },
  }
});

export const smbStateActions = smbState.actions;

export default smbState.reducer;
